.login {
  width: 100%;
  height: 100dvh;
  display: flex;
  align-items: center;
  justify-content: center;
  &__form {
    max-width: 320px;
    width: 100%;
  }
  &__head {
    padding: 20px 0px;
    &__title {
      font-size: 30px;
      margin: 0px;
      padding: 0px;
    }
    &__subtitle {
      font-size: 18px;
      font-weight: 300;
      color: $gray;
    }
  }
}

@media (max-width: 767px) {
  .login {
    height: auto;
    max-width: 380px;
    margin: 0px auto;
    &__container {
      width: 100%;
      padding: 40px 20px;
    }
  }
}
