.template-card {
  width: 33.3%;
  padding: 20px;
}

@media (min-width: 1600px) {
  .template-card {
    width: 25%;
  }
}

@media (max-width: 1140px) {
  .template-card {
    width: 50%;
  }
}

@media (max-width: 767px) {
  .template-card {
    width: 100%;
    padding: 10px 0px;
  }
}
