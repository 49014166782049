.vobo-sidebar {
  &__message {
    font-size: 18px;
    font-size: 500;
  }
  &__controls {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0px;
  }
  &__reject {
    background-color: $red;
    &:hover, &:active, &:focus {
      background-color: $red !important;
      opacity: 0.9;
    }
  }
}