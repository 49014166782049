.auth-layout {
  &__identity {
    width: 100%;
    height: 100dvh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(-45deg, #FAFAFA, #DCEDC8, #B2EBF2, #000000);
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
  }
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

@media (max-width: 767px) {
  .auth-layout {
    &__identity {
      height: auto;
    }
  }
}
