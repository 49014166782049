body {
  margin: 0px;
  padding: 0px;
  font-family: $font-default !important;
}

button {
  font-family: $font-default;
}
a {
  color: var(--theme-color);
  &:hover, &:active, &:focus {
    color: var(--theme-color);
    opacity: 0.8;
  }
}