.vobo-comments {
  &__header {
    padding: 10px 0px;
    border-bottom: 1px solid $lightgray;
  }
  &__title {
    font-size: 16px;
    font-weight: 600;
  }
  &__item {
    padding: 5px 0px;
  }
  &__container {
    padding-bottom: 20px;
  }
}