.document-info {
  &__data {
    margin-bottom: 1em;
    &__title {
      font-weight: bold;
      margin-bottom: 5px;
    }
    &__image {
      border-radius: 10px;
      box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.2);
      padding: 10px;
      max-width: 160px;
      img {
        max-width: 100%;
      }
    }
  }
  &__columns {
    padding-top:30px;
    .document-info__data {
      width: 33.3%;
    }
  }
  &__actions {
    padding: 90px 0px 20px;
  }

@media (max-width: 767px) {
  .document-info__data {
    width: 100%;
  }
  .document-info__columns {
    display: block;
    text-align: center;
  }
}

}
