#documentForm {
  padding: 20px;
  background: $lightgray;
  .ant-input, .ant-select {
    max-width: 420px;
  }
  .ant-typography {
    margin: 0px 0px 10px;
    border-bottom: 1px solid var(--theme-dark);
    display: inline-block;
  }
}
.vobo {
  &__preview {
    &__header {
      max-height: 65px;
      .label-light {
        font-size: 14px;
      }
    }
    &__container {
      height: calc(100dvh - 160px);
    }
  }
  &__header {
    border-bottom: 1px solid rgba(5, 5, 5, 0.06);
  }
  &__sidebar {
    width: 320px !important;
    max-width: 320px !important;
    flex: 0 0 320px !important;
    &.ant-layout-sider-collapsed {
      flex: 0 0 0px !important;
      width: 0px !important;
      max-width: 0px !important;
      min-width: 0px !important;
    }
  }
  &__response {
    height: calc(100dvh - 140px);
    display: flex;
    justify-content: center;
    align-items: center;
    &__signature {
      &__instructions {
        padding-top: 10px;
        color: $gray;
      }
      ul li {
        text-align: left;
      }
    }
  }
  .ant-result-success .ant-result-icon>.anticon {
    color: var(--theme-color);
  }
}
.search {
  margin-bottom:10px;
  display: flex;
  width: 100%;
  &__input {
    padding: 5px;
    width: 100%;
  }
  &__button {
    width: 30%;
  }
}

@media (max-width: 767px) {
  .vobo__controls {
    display: flex;
    padding-top: 15px;
  }
  .vobo__preview {
    padding-top: 40px;
  }
  .vobo__header__hi {
    width: 100%;
    line-height: 1.2em;
    padding: 20px 0px;
  }
}
