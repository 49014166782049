.header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  &__logo {
    padding:10px;
    max-height: 60px;
  }
  &__user {
    &__avatar {
      width: 45px;
      height: 45px;
      background-color: var(--theme-color);
      border: none;
      outline: none;
      @include radius();
      color: $white;
      font-size: 20px;
      font-weight: 300;
      &:hover, &:focus {
        opacity: 0.8;
        box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.2)
      }
    }
  }
  &__menu {
    display: flex;
    align-items: center;
  }
}
