.loader-custom {
  &--fullpage {
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,255,0.5);
    position: fixed;
    top: 0px;
    left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
}